<template>
  <div class="file-info-container">
    <h2>名称: {{ fileInfo.name }}</h2>
    <p>版本: {{ fileInfo.version }}</p>
    <p>描述: {{ fileInfo.description }}</p>
    <div class="upload-and-download">
      <p>上传时间: {{ fileInfo.uploadTime }}</p>
      <a href="javascript:void(0)" @click="confirmDownload">下载软件</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileDownload',
  data() {
    return {
      fileInfo: {
        name: '汇通金财RPA设计器',
        version: '2.3.1',
        description: '汇通金财软件机器人是面向RPA（机器人流程自动化）的卓越可视化解决方案，致力于通过一套直观易用、高效可靠的可视化工具，为用户提供行业领先的RPA应用实践，帮助用户优化流程、提升效率，实现价值最大化。',
        uploadTime: '2024-11-20',
      },
    };
  },
  methods: {
    confirmDownload() {
      if (confirm('确定要下载设计器吗？')) {
        // 如果点击确定，通过创建一个动态的<a>标签来触发下载
        const link = document.createElement('a');
        link.href = '/汇通金财RPA设计器安装包.exe';
        link.download = '汇通金财RPA设计器安装包.exe';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
};
</script>


<style scoped>
.file-info-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.upload-and-download {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}
</style>
